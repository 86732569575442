@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

.container {
  padding-left: 5%;
  padding-right: 5%;
  margin: auto;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  transition: 0.6;
}

.navbar img {
  width: 75px;
}

.herosection {
  width: 100%;
  height: 100vh;
  margin-top: 75px;
  padding-bottom: 100px;
}

.herosection .info-section {
  /* width: 65%; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .herosection .info-section p {
  width: 100%;
} */
.herosection .image-section {
  display: flex;
  justify-content: start;
  aspect-ratio: 2/3;
}

.avi_color{
  color: #FF0066;
}
.avi_bg_color{
  background-color: #FF0066;
}

.button-1 {
  background: #FF0066;
}

.button-2 {
  border: 1px solid #FF0066;
}

.button {
  background: #FF0066;
  border: 1px solid #FF0066;
}

.line{
  width: 10%;
  height: 8px;
  background-color: #FF0066;
  border-radius: 8px;
}

.herosection::before {
  position: absolute;
  display: block;
  bottom: 0;
  width: 10%;
  left: 45%;
  border-bottom: 8px solid #FF0066;
  border-radius: 8px;
  content: "";
}

.footer {
  background-color: #14151A;
}

.footer img {
  width: 75px;
}

.avi_input {
  background-color: #1E212A;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
}

@media screen and (max-width: 1280px) and (min-height: 1080px) {
  .herosection .image-section{
    padding-top: 10%;
    aspect-ratio: 4/5;
    padding-bottom: 10%; 
    width: 55%;
  }
  
  .herosection .info-section{
    width: 45%;
  }
}

/* @media screen and (max-width: 667px) and (max-height: 667px) {
  .herosection .image-section{
    aspect-ratio: 4/5;
    justify-content: center;
    padding-top: 20px;
  }
} */

/* @media screen and (min-width: 1280px) {
  .herosection .image-section{
    padding-top: 2%;
    padding-bottom: 2%;
  }
} */
@media screen and (min-width: 1600px) {
  .herosection .info-section{
    width: 60%;
  }
  .herosection .image-section{
    padding-top: 2%;
    padding-bottom: 2%;
  }
}
@media screen and (min-width: 1920px) {
  .herosection .info-section{
    width: 55%;
  }
  .herosection .image-section{
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (min-width: 2560px) {
  .herosection .info-section{
    width: 50%;
  }
  .herosection .image-section{
    padding-top: 100px;
    padding-bottom: 100px;
  }
}